<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Facturation</h4>
    </div>
    <hr class="" />
    <CoolLightBox :items="items" :index="index" @close="index = null"> </CoolLightBox>
    <mdb-card class="z-depth-1">
      <mdb-card-body class="card-reversed">
        <form @submit.prevent="updateInv()">
          <mdb-row>
            <mdb-col sm="12" md="6">
              <mdb-select
                flipOnScroll
                wrapperClass=""
                labelClass=" color-normal-reversed"
                caretClass="color-primary-reversed "
                :disabled="!$gate.hasPermission(['super-admin'])"
                :class="'mb-n3 '"
                @getValue="getRegime"
                :validation="$store.state.setting.errors.regime_fiscal ? true : false"
                :invalidFeedback="$store.state.setting.errors.regime_fiscal"
                v-model="regimeOption"
                outline
                label="Régime fiscal"
                size="md"
              ></mdb-select>
              <div
                style="color: #dc3545; font-size: 0.8rem"
                v-if="$store.state.setting.errors.regime_fiscal"
              >
                {{ $store.state.setting.errors.regime_fiscal }}
              </div>
            </mdb-col>
            <mdb-col sm="12" md="6">
              <mdb-input
                wrapperClass=""
                inputClass="border-reversed color-normal-reversed"
                labelClass=" color-normal-reversed"
                :disabled="!$gate.hasPermission(['super-admin'])"
                :validation="$store.state.setting.errors.nim ? true : false"
                :invalidFeedback="$store.state.setting.errors.nim"
                v-model="nim"
                outline
                label="NIM"
                size="md"
              ></mdb-input>
            </mdb-col>
            <mdb-col sm="12" md="6">
              <mdb-input
                wrapperClass=""
                inputClass="border-reversed color-normal-reversed"
                labelClass=" color-normal-reversed"
                :disabled="!$gate.hasPermission(['edit::setting'])"
                :validation="$store.state.setting.errors.signatory ? true : false"
                :invalidFeedback="$store.state.setting.errors.signatory"
                v-model="form.signatory"
                outline
                label="Signataire"
                size="md"
              ></mdb-input>
            </mdb-col>
            <mdb-col sm="12" md="6">
              <mdb-input
                wrapperClass=""
                inputClass="border-reversed color-normal-reversed"
                labelClass=" color-normal-reversed"
                :disabled="!$gate.hasPermission(['edit::setting'])"
                :validation="$store.state.setting.errors.signatory_title ? true : false"
                :invalidFeedback="$store.state.setting.errors.signatory_title"
                v-model="form.signatory_title"
                outline
                label="Titre du signataire"
                size="md"
              ></mdb-input>
            </mdb-col>

            <mdb-col sm="12" md="6" class="mb-2">
              <label :class="'color-normal-reversed'" class="darken-5 d-block" for=""
                >Theme Principal des Factures.</label
              >
              <el-color-picker
                @change="colorChange"
                color-format="hex"
                :disabled="!$gate.hasPermission(['edit::setting'])"
                v-model="theme_color"
              ></el-color-picker>
            </mdb-col>

            <mdb-col sm="12" md="6" class="color-normal-reversed darken-1">
              <label :class="'color-normal-reversed'" for=""
                >Numérotation des factures?</label
              >
              <mdb-switch
                :disabled="!$gate.hasPermission(['edit::setting'])"
                v-model="form.is_automatic"
                offLabel="Manuelle"
                onLabel="Automatique"
              />
            </mdb-col>

            <mdb-col
              sm="12"
              md="6"
              class="mt-2 color-normal-reversed"
              v-if="!$gate.hasPermission('user')"
            >
              <label :class="'color-normal-reversed'" for="">Signature numérique</label>
              <mdb-switch
                :disabled="!$gate.hasPermission(['edit::setting'])"
                v-model="form.use_digital_signature"
                offLabel="Non"
                onLabel="Oui"
              />
              <mdb-card
                class="card-logo"
                v-if="$store.state.auth.company.setting.digital_signature"
                style="width: 100px; position: relative"
              >
                <mdb-view>
                  <a>
                    <mdb-card-image
                      id="logo"
                      :src="
                        $store.state.url +
                        'storage/signatories/' +
                        $store.state.auth.company.setting.digital_signature
                      "
                      alt="Card image cap"
                    ></mdb-card-image>
                  </a>
                </mdb-view>
              </mdb-card>
              <el-upload
                class="upload-demo mt-3"
                ref="upload"
                :action="
                  $store.state.url +
                  'api/settings/digital_signature/' +
                  this.form.id +
                  '?type_setting=inv'
                "
                :headers="{
                  Authorization: 'Bearer ' + $store.state.auth.token,
                }"
                :accept="'image/png'"
                :list-type="'picture'"
                :limit="1"
                :auto-upload="true"
                :on-success="digital_signature_success"
                :on-error="digital_signature_error"
              >
                <el-button slot="trigger" size="small" type="primary">Ajouter</el-button>
                <el-button
                  v-if="$store.state.auth.company.setting.digital_signature"
                  style="margin-left: 10px"
                  size="small"
                  type="danger"
                  @click="deleteDigitalSignature"
                  >Supprimer</el-button
                >
                <div class="el-upload__tip color-normal-reversed" slot="tip">
                  Fichiers png avec une taille inférieure à 2000kb <br />
                  <a
                    href="https://www.docsketch.com/online-signature/draw/"
                    target="blank"
                    >Vous n'avez pas de signature numérique? <br />
                    Cliquez ici pour en générer gratuitement.</a
                  >
                </div>
              </el-upload>
            </mdb-col>

            <mdb-col sm="12" md="6" class="color-normal-reversed darken-1">
              <label :class="'color-normal-reversed'" for=""
                >Envoi des factures aux clients?</label
              >
              <mdb-switch
                :disabled="!$gate.hasPermission(['edit::setting'])"
                v-model="form.send_invoice_customer"
                offLabel="Manuel"
                onLabel="Automatique"
              />
            </mdb-col>

            <mdb-col
              v-if="$gate.hasPermission(['super-admin'])"
              sm="12"
              md="6"
              class="grey-text darken-1 mt-2"
            >
              <label :class="'color-normal-reversed'" for="">Quantité Unifiée?</label>
              <mdb-switch
                :disabled="!$gate.hasPermission(['super-admin'])"
                v-model="form.unify_quantity"
                offLabel="Non"
                onLabel="Oui"
              />
            </mdb-col>

            <mdb-col sm="12" class="d-flex justify-content-end">
              <button
                v-if="$gate.hasPermission(['edit::setting'])"
                type="submit"
                class="btn btn-primary btn-md"
              >
                <span v-if="!submitBtn">Sauvegarder</span>
                <span
                  v-if="submitBtn"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="submitBtn" class="pl-2">Loading...</span>
              </button>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-card-body>
    </mdb-card>

    <div class="mt-5 d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Numérotation Des Factures</h4>
    </div>
    <hr class="" />
    <mdb-card cascade class="z-depth-1">
      <mdb-card-body class="card-reversed">
        <form @submit.prevent="updateNumFacture()">
          <p style="font-size: 13px" class="color-normal-reversed">
            Cette Section vous permet de définir le mode de numérotation (Manuelle ou
            Automatique) de vos factures.
          </p>
          <p style="font-size: 13px" class="text-danger">
            Important: Une fois enregistré les champs prefixe,taille et série ne seront
            plus modifiable. <br />
            Mais vous pouvez toutefois changer de mode de numérotation (Manuelle ou
            Automatique) à tout moment.
          </p>
          <p style="font-size: 13px" class="text-warning">
            Important: Le changement de mode de numérotation remet à 0 la numérotation
            automatique.
          </p>
          <mdb-row>
            <!-- <mdb-col sm="12"   md="12" class="grey-text darken-1">
                            <label :class="'color-normal-reversed'" for="">Numérotation des factures?</label>
                            <mdb-switch :disabled="!$gate.hasPermission(['super-admin','admin'])" v-model="formNum.is_automatic" offLabel="Manuelle" onLabel="Automatique"  />
                        </mdb-col> -->
            <mdb-col sm="12" md="3">
              <mdb-input
                wrapperClass=""
                inputClass="border-reversed color-normal-reversed"
                labelClass=" color-normal-reversed"
                :disabled="$store.state.auth.company.setting.num_facture_status === 1"
                placeholder="Ex: FV"
                v-mask="'AA'"
                :validation="
                  $store.state.setting.errors.num_facture_prefixe ? true : false
                "
                :invalidFeedback="$store.state.setting.errors.num_facture_prefixe"
                v-model="formNum.num_facture_prefixe"
                outline
                label="Préfixe"
                size="md"
              ></mdb-input>
            </mdb-col>
            <mdb-col sm="12" md="3">
              <mdb-input
                wrapperClass=""
                inputClass="border-reversed color-normal-reversed"
                labelClass=" color-normal-reversed"
                :disabled="$store.state.auth.company.setting.num_facture_status === 1"
                placeholder="Min 4 & Max 7"
                :validation="$store.state.setting.errors.num_facture_size ? true : false"
                :invalidFeedback="$store.state.setting.errors.num_facture_size"
                v-model="formNum.num_facture_size"
                type="number"
                :step="1"
                outline
                label="Taille"
                size="md"
              ></mdb-input>
            </mdb-col>
            <mdb-col sm="12" md="6">
              <mdb-select
                flipOnScroll
                wrapperClass=""
                labelClass=" color-normal-reversed"
                caretClass="color-normal-reversed "
                :disabled="$store.state.auth.company.setting.num_facture_status === 1"
                class="mb-n3"
                @getValue="getSerie"
                :validation="$store.state.setting.errors.num_facture_serie ? true : false"
                :invalidFeedback="$store.state.setting.errors.num_facture_serie"
                v-model="serieOption"
                outline
                label="Série"
                size="md"
              ></mdb-select>
              <div
                style="color: #dc3545; font-size: 0.8rem"
                v-if="$store.state.setting.errors.num_facture_serie"
              >
                {{ $store.state.setting.errors.num_facture_serie }}
              </div>
            </mdb-col>
            <mdb-col sm="12" class="d-flex align-items-center justify-content-between">
              <div
                class="z-depth-1 px-3 py-2 screen-ex text-primary"
                style="font-size: 1.2rem"
              >
                <span class="text-success">{{ exCode[0] }}</span>
                <span class="text-danger">{{ exCode[1] }}</span>
                <span class="text-primary">{{ exCode[2] }}</span>
              </div>
              <button
                v-if="
                  $gate.hasPermission(['edit::setting']) &&
                  $store.state.auth.company.setting.num_facture_status !== 1
                "
                type="submit"
                class="btn btn-primary btn-md"
              >
                <span v-if="!submitBtn">Sauvegarder</span>
                <span
                  v-if="submitBtn"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="submitBtn" class="pl-2">Loading...</span>
              </button>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-card-body>
    </mdb-card>

    <div class="mt-5 d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Modèle facture</h4>
      <div>
        <router-link to="/place-marche"> voir plus </router-link>
      </div>
    </div>
    <hr class="" />
    <mdb-card
      class="z-depth-1"
      cascade
      v-if="$gate.hasPermission(['edit::setting'])"
    >
      <mdb-card-body>
        <mdb-row v-if="$gate.hasPermission(['edit::setting'])">
          <mdb-col
            sm="12"
            md="4"
            lg="4"
            class="image mb-4"
            style="position: relative"
            v-for="(image, imageIndex) in modeles"
            :key="imageIndex"
          >
            <div>
              <mdb-card wide>
                <mdb-view hover cascade>
                  <a>
                    <mdb-card-image
                      :src="$store.state.url + 'storage/models/' + image.image"
                      alt="Card image cap"
                      style="width: 100%; height: 200px"
                    ></mdb-card-image>
                    <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                  </a>
                </mdb-view>
                <mdb-card-body
                  class="text-primary d-flex justify-content-between align-items-center px-2 py-2"
                  cascade
                >
                  <div class="title">
                    <div style="font-weight: 500; font-size: 0.9rem">
                      {{ image.name }}
                    </div>
                    <div class="type" style="font-size: 0.7rem">
                      <span
                        v-if="image.type === 'free'"
                        class="badge bg-success text-uppercase"
                        >Gratuit</span
                      >
                      <span
                        v-if="image.type !== 'free'"
                        class="badge bg-primary text-uppercase"
                        >Payant</span
                      >
                    </div>
                  </div>

                  <div class="f-action d-flex justify-content-between">
                    <mdb-tooltip trigger="hover" :options="{ placement: 'bottom' }">
                      <span slot="tip">Voir</span>
                      <button
                        @click="index = imageIndex"
                        slot="reference"
                        style="border: none; background: transparent"
                      >
                        <i class="fas fa-eye text-primary"></i>
                      </button>
                    </mdb-tooltip>
                    <mdb-tooltip trigger="hover" :options="{ placement: 'bottom' }">
                      <span slot="tip" v-if="image.id !== $store.state.auth.model.id"
                        >Appliquer</span
                      >
                      <span slot="tip" v-else>Utilisé</span>
                      <button
                        @click="
                          image.id !== $store.state.auth.model.id
                            ? activeModel({
                                mi: image.id,
                                ci: $store.state.auth.company.id,
                              })
                            : ''
                        "
                        slot="reference"
                        style="border: none; background: transparent"
                      >
                        <i
                          class="fas fa-check-square"
                          :class="
                            image.id !== $store.state.auth.model.id
                              ? ' text-primary'
                              : ' text-success'
                          "
                        ></i>
                      </button>
                    </mdb-tooltip>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </mdb-col>
        </mdb-row>
      </mdb-card-body>
    </mdb-card>

    <div
      class="mt-5 d-flex justify-content-between align-items-center mb-n2"
      v-if="$gate.hasPermission(['edit::setting'])"
    >
      <h4 style="font-weight: 500">Suppression des factures non normalisées</h4>
    </div>
    <hr class="" />
    <mdb-card
      class="z-depth-1"
      cascade
      v-if="$gate.hasPermission(['edit::setting'])"
    >
      <mdb-card-body>
        <div class="rows">
          <div class="col-md-12">
            Cette action ne peut pas être annulée. Cette action supprimera définitivement
            les factures non normalisées ou en attente de normalisation existant dans
            votre entreprise. <br />
            <span class="text-danger">
              Ceci n'épargne pas les nouvelles factures éditées en attente de
              normalisation.</span
            >
            <br />
            <br />
            <span class="font-weight-bold">
              Veuillez taper
              <span class="text-danger">{{ this.goten }}</span> pour confirmer.</span
            >
          </div>
          <div class="rows">
            <div class="col-md-7">
              <mdb-input outline size="md" v-model="input_goten" type="text"></mdb-input>
              <button
                type="button"
                class="btn btn-outline-danger btn-block btn-md"
                @click="deleteAllInvoice()"
                :disabled="goten === input_goten ? false : true"
              >
                <span v-if="!invoiceDeleterLoading">
                  je comprends les conséquences, supprimer les factures non normalisées
                </span>
                <span
                  v-if="invoiceDeleterLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="invoiceDeleterLoading" class="pl-2">Loading...</span>
              </button>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</template>

<script>
import RegimeOption from "@/utils/Options/RegimeOption";
import randomstring from "randomstring";
import {
  mdbView,
  mdbInput,
  mdbSelect,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbSwitch,
  mdbMask,
  mdbTooltip,
  mdbCardImage,
} from "mdbvue";
export default {
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - PARAMETRE FACTURATION",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    mdbView,
    mdbInput,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbSwitch,
    mdbMask,
    mdbCardImage,
    mdbTooltip,
  },
  data() {
    return {
      document: "",
      modeles: [],
      items: [],
      index: null,
      regimes: RegimeOption,
      regimeOption: RegimeOption,
      submitBtn: false,
      invoiceDeleterLoading: false,
      theme_color: this.$store.state.auth.company.setting.theme_color,
      regime_fiscal: this.$store.state.auth.company.setting.regime_fiscal,
      nim: this.$store.state.auth.company.setting.nim,
      form: {
        id: this.$store.state.auth.company.setting.id,
        is_automatic:
          this.$store.state.auth.company.setting.is_automatic === 0 ? false : true,
        signatory: this.$store.state.auth.company.setting.signatory,
        signatory_title: this.$store.state.auth.company.setting.signatory_title,
        unify_quantity:
          this.$store.state.auth.company.setting.unify_quantity === 0 ? false : true,
        use_digital_signature:
          this.$store.state.auth.company.setting.use_digital_signature === 0
            ? false
            : true,
        send_invoice_customer:
          this.$store.state.auth.company.setting.send_invoice_customer === 0
            ? false
            : true,
      },
      formNum: {
        id: this.$store.state.auth.company.setting.id,
        num_facture_prefixe: this.$store.state.auth.company.setting.num_facture_prefixe,
        num_facture_serie: this.$store.state.auth.company.setting.num_facture_serie,
        num_facture_size: this.$store.state.auth.company.setting.num_facture_size,
      },
      serieOption: [
        {
          text: "Mensuelle",
          value: "monthly",
          selected: false,
        },
        {
          text: "Annuelle",
          value: "annually",
          selected: false,
        },
        {
          text: "Chronologique",
          value: "chronology",
          selected: false,
        },
      ],

      goten: "",
      input_goten: "",
    };
  },

  methods: {
    colorChange(value) {
      if (value) {
        this.theme_color = value;
      } else {
        this.theme_color = "";
      }
    },

    showOrHideAction(value) {
      let clist = document.querySelector("." + value).classList;
      let n = false;
      clist.forEach((liste) => {
        if (liste === "show-action") {
          n = true;
        }
      });

      switch (n) {
        case false:
          document.querySelector("." + value).classList.remove("hide-action");
          document.querySelector("." + value).classList.add("show-action");
          break;
        case true:
          document.querySelector("." + value).classList.remove("show-action");
          document.querySelector("." + value).classList.add("hide-action");
          break;
        default:
          break;
      }
    },

    submitUpload() {
      this.$refs.upload.submit();
    },

    async deleteAllInvoice() {
      this.$nprogress.start();
      this.invoiceDeleterLoading = !this.invoiceDeleterLoading;

      await this.$store
        .dispatch("invoice/deleteAllInvoices")
        .then((response) => {
          this.$nprogress.done();
          this.invoiceDeleterLoading = !this.invoiceDeleterLoading;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.goten = randomstring.generate(5).toUpperCase();
          this.input_goten = "";
        })
        .catch((error) => {
          this.$nprogress.done();
          this.invoiceDeleterLoading = !this.invoiceDeleterLoading;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    digital_signature_success(response) {
      this.$refs.upload.clearFiles();
      this.$store.dispatch("auth/attempt", localStorage.getItem("token"));
      this.$notify({
        message: response.message,
        type: "success",
      });
    },

    digital_signature_error(error) {
      let errorMsg = JSON.parse(error.message);
      this.$refs.upload.clearFiles();
      this.$notify({
        message: errorMsg.message,
        type: "danger",
      });
    },

    setRegimfisc() {
      this.regimeOption = [];

      this.regimes.forEach((rf) => {
        this.regimeOption.push({
          text: rf.text,
          value: rf.value,
          selected:
            this.$store.state.auth.company.setting.regime_fiscal === rf.value
              ? true
              : false,
        });
      });

      this.regimeOption;
    },

    getRegime(value) {
      this.regime_fiscal = value;
    },

    getSerie(value) {
      this.formNum.num_facture_serie = value;
    },

    async updateInv() {
      if (this.$gate.hasPermission(["super-admin"])) {
        this.form.regime_fiscal = this.regime_fiscal;
        this.form.nim = this.nim;
      } else {
        this.form.regime_fiscal = this.$store.state.auth.company.setting.regime_fiscal;
        this.form.nim = this.$store.state.auth.company.setting.nim;
      }
      this.$nprogress.start();
      this.$store.commit("setting/SET_CLEAN");
      this.submitBtn = !this.submitBtn;
      if (this.theme_color) {
        this.form.theme_color = this.theme_color;
      }
      // this.form.theme_color = (this.form.theme_color === undefined) ? '' : this.form.theme_color
      await this.$store
        .dispatch("setting/updateInv", this.form)
        .then((response) => {
          this.$nprogress.done();
          this.submitBtn = !this.submitBtn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.$store.dispatch("auth/attempt", localStorage.getItem("token"));
        })
        .catch((error) => {
          this.$nprogress.done();
          this.submitBtn = !this.submitBtn;
          if (error.response.data.errors) {
            this.$store.commit(
              "setting/SET_RF",
              error.response.data.errors.regime_fiscal
            );
            this.$store.commit("setting/SET_NIM", error.response.data.errors.nim);
            this.$store.commit("setting/SET_SIGN", error.response.data.errors.signatory);
            this.$store.commit(
              "setting/SET_ST",
              error.response.data.errors.signatory_title
            );
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async activeModel(data) {
      let res = await this.$store.dispatch("swal/ultimate", {
        type: "warning",
        icon: "warning",
        title: "Etes-vous sûre?",
      });
      if (res.isDismissed) {
        return;
      }
      this.$store.commit("setting/SET_CLEAN");
      await this.$store
        .dispatch("model_invoice/activeModel", data)
        .then((response) => {
          this.loadModelInvoices();
          this.$store.dispatch("auth/attempt", localStorage.getItem("token"));
          this.loadModelInvoices();
          this.$notify({
            message: response.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          this.submitBtn = !this.submitBtn;
          if (error.response.data.errors) {
            this.$store.commit(
              "setting/SET_RF",
              error.response.data.errors.regime_fiscal
            );
            this.$store.commit("setting/SET_NIM", error.response.data.errors.nim);
            this.$store.commit("setting/SET_SIGN", error.response.data.errors.signatory);
            this.$store.commit(
              "setting/SET_ST",
              error.response.data.errors.signatory_title
            );
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    reinit() {
      this.items = [];
    },

    async loadModelInvoices() {
      this.$nprogress.start();
      this.reinit();
      await this.$store
        .dispatch("model_invoice/findAll", {
          mode: "simple",
        })
        .then((response) => {
          this.$nprogress.done();
          this.modeles = response.data.modeles
            .filter(
              (model) =>
                model.code_model !== this.$store.state.auth.model.code_model &&
                model.type === "free"
            )
            .slice(0, 2);
          this.modeles.splice(0, 0, this.$store.state.auth.model);
          this.modeles.forEach((m) => {
            this.items.push({
              title: m.name,
              description: "",
              src: this.$store.state.url + "storage/models/" + m.image,
              currently: false,
              type: m.type,
              id: m.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteDigitalSignature() {
      let res = await this.$store.dispatch("swal/ultimate", {
        title: "Etes-vous sûre?",
        type: "danger",
        icon: "error",
      });

      if (res.isDismissed) {
        return;
      }
      await this.$store
        .dispatch(
          "setting/deleteDigitalSignature",
          this.$store.state.auth.company.setting.id
        )
        .then((response) => {
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.$store.dispatch("auth/attempt", localStorage.getItem("token"));
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async updateNumFacture() {
      if (this.$store.state.auth.company.setting.num_facture_status === 0) {
        let res = await this.$store.dispatch("swal/ultimate", {
          type: "warning",
          icon: "warning",
          title: "Etes-vous sûre?",
          message: "Cette action est irréversible.",
        });
        if (res.isDismissed) {
          return;
        }
      }
      this.$nprogress.start();
      this.$store.commit("setting/SET_CLEAN");
      this.submitNumFactureBtn = !this.submitNumFactureBtn;
      await this.$store
        .dispatch("setting/updateNumFacture", this.formNum)
        .then((response) => {
          this.$nprogress.done();
          this.submitNumFactureBtn = !this.submitNumFactureBtn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.$store.dispatch("auth/attempt", localStorage.getItem("token"));
        })
        .catch((error) => {
          this.$nprogress.done();
          this.submitNumFactureBtn = !this.submitNumFactureBtn;
          if (error.response.data.errors) {
            this.$store.commit(
              "setting/SET_NFP",
              error.response.data.errors.num_facture_prefixe
            );
            this.$store.commit(
              "setting/SET_NFS",
              error.response.data.errors.num_facture_size
            );
            this.$store.commit(
              "setting/SET_NFSE",
              error.response.data.errors.num_facture_serie
            );
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    generateExCode() {
      let $size = ["0001", "00001", "000001", "0000001"];

      let $prefixe = this.formNum.num_facture_prefixe;
      let $date = this.$moment().format("YYMMDD");
      let $numberSize = "";

      switch (this.formNum.num_facture_size) {
        case "4":
          $numberSize = $size[0];
          break;
        case "5":
          $numberSize = $size[1];
          break;
        case "6":
          $numberSize = $size[2];
          break;
        case "7":
          $numberSize = $size[3];
          break;
        default:
          break;
      }

      return [$prefixe, $date, $numberSize];
    },
  },

  created() {
    this.setRegimfisc();
    this.loadModelInvoices();

    this.serieOption.forEach((se) => {
      if (se.value === this.$store.state.auth.company.setting.num_facture_serie) {
        se.selected = true;
      }
    });

    // Model currently
  },

  mounted() {
    this.document = document;

    this.goten = randomstring.generate(5).toUpperCase();
  },

  computed: {
    exCode() {
      return this.generateExCode();
    },
    regimFiscal() {
      return this.setRegimfisc();
    },
  },
};
</script>

<style scoped lang="scss">
.action-model {
  width: 90%;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 655;

  animation: fadeIn 400ms;
  -webkit-animation: fadeIn 400ms;
  -moz-animation: fadeIn 400ms;
  -o-animation: fadeIn 400ms;
  -ms-animation: fadeIn 400ms;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

.hide-action {
  display: none;
}

.show-action {
  display: block;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
