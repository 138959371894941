import { render, staticRenderFns } from "./InvoiceSetting.vue?vue&type=template&id=8bea3052&scoped=true&"
import script from "./InvoiceSetting.vue?vue&type=script&lang=js&"
export * from "./InvoiceSetting.vue?vue&type=script&lang=js&"
import style0 from "./InvoiceSetting.vue?vue&type=style&index=0&id=8bea3052&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.7.10_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bea3052",
  null
  
)

export default component.exports